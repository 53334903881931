(function($) {
  let $body = $('body');
  let $gotop = $('.gotop');
  let menuOpenSpeed = 300;
  let isMobile = false;
  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;

  // IE關閉smooth scroll
  if(navigator.userAgent.match(/Trident\/7\./)) {
    document.body.addEventListener("mousewheel", function() {
      event.preventDefault();
      let wd = event.wheelDelta;
      let csp = window.pageYOffset;
      window.scrollTo(0, csp - wd);
    });
  }
  $('.nav-toggle').click(function(event) {
    $body.toggleClass('nav-open');
    return false;
  });

  $('.side-nav-toggle').click(function(event) {
    $(this).parent('.side-nav').toggleClass('open');
    return false;
  });

  $(document).on('click', function(e){
    if($(e.target).parents('.nav').length==0){
      $body.removeClass('nav-open');
    }
    if($(e.target).parents('.side-nav').length==0){
      $('.side-nav').removeClass('open');
    }
  });

  $('.bt-scroll').click(function(event) {
    var t = $(this).attr('href');
    $('html,body').stop().animate({
      scrollTop: $(t).offset().top
    }, 400);
    return false;
  });
  $gotop.click(function(event) {
    $('html,body').stop().animate({
      scrollTop: 0
    }, 400);
    return false;
  });

  $(window).on('scroll', function(event) {
    if ($(window).scrollTop() > 50) {
      $body.addClass('header-fixed');
    } else {
      $body.removeClass('header-fixed');
    }
    if ($(window).scrollTop() < $('.banner').outerHeight()) {
      $body.addClass('banner-visible');
    } else {
      $body.removeClass('banner-visible');
    }
    if ($(window).scrollTop() > $body.height() - $(window).height() - $('.footer').outerHeight()) {
      $body.addClass('footer-reach');
    } else {
      $body.removeClass('footer-reach');
    }
    // if ($('.main-content').is(':visible') && $(window).scrollTop() > $('.main-content').offset().top) {
    //   $body.addClass('content-pass');
    // } else {
    //   $body.removeClass('content-pass');
    // }
    // if ($('.main-content').is(':visible') && $(window).scrollTop() > $('.main-content').offset().top - $('.site-title').outerHeight() - 80) {
    //   $body.addClass('logo-hide');
    // } else {
    //   $body.removeClass('logo-hide');
    // }
    // if ($(window).scrollTop() > $('body').outerHeight() - $(window).height() - $('.footer').outerHeight()) {
    //   $body.addClass('footer-pass');
    // } else {
    //   $body.removeClass('footer-pass');
    // }
  }).scroll();

  let lastScrollTop = $(window).scrollTop();
  let st = $(window).scrollTop();
  $(window).on('load', function() {
    $('.anime').each(function () {
      let currSect = $(this);
      $('.anime').each(function () {
        let currSect = $(this);
        if (currSect.offset().top - $(window).height() <= st) {
          currSect.addClass("anime-run");
        }
      });
    });
  });
  $(window).on('scroll', function(event) {
    st = $(window).scrollTop();
    if (st > lastScrollTop){
      $('.anime').each(function () {
        let currSect = $(this);
        if (currSect.offset().top - $(window).height()*0.7 <= st && currSect.offset().top + $(window).height()*0.7 > st) {
          currSect.addClass("anime-run");
        }
      });
    } else {
      $('.anime').each(function () {
        let currSect = $(this);
        if (currSect.offset().top - $(window).height()*0.5 <= st && currSect.offset().top > st) {
          currSect.addClass("anime-run");
        }
      });
    }
    lastScrollTop = st;
  });
})($)
